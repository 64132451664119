import constants from "./constants";

// Strategy for averageSessionDuration
export const averageSessionDurationStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const averageSessionDurationInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return averageSessionDurationInOneMonth
        ? averageSessionDurationInOneMonth.averageDurationInSeconds / 60
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const averageSessionDurationInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return averageSessionDurationInOneDay
        ? averageSessionDurationInOneDay.averageDurationInSeconds / 60
        : 0;
    });
  }
};

// Strategy for averageMessagesPerSession
export const averageMessagesPerSessionStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const averageMessagesPerSessionInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return averageMessagesPerSessionInOneMonth
        ? parseInt(
            averageMessagesPerSessionInOneMonth.averageMessagesPerSession
          )
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const averageMessagesPerSessionInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return averageMessagesPerSessionInOneDay
        ? parseInt(averageMessagesPerSessionInOneDay.averageMessagesPerSession)
        : 0;
    });
  }
};

// Strategy for retentionRate
export const retentionRateStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const retentionRateInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return retentionRateInOneMonth
        ? retentionRateInOneMonth.retentionRate
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const retentionRateInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return retentionRateInOneDay ? retentionRateInOneDay.retentionRate : 0;
    });
  }
};

// Strategy for ai success performance rate
export const aiSuccessPerformanceRateStartStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const aiSuccessPerformanceRateInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return aiSuccessPerformanceRateInOneMonth
        ? aiSuccessPerformanceRateInOneMonth.successRate
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const aiSuccessPerformanceRateInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return aiSuccessPerformanceRateInOneDay
        ? aiSuccessPerformanceRateInOneDay.successRate
        : 0;
    });
  }
};

// Strategy for ai failure performance rate
export const aiFailurePerformanceRateStartStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const aiFailurePerformanceRateInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return aiFailurePerformanceRateInOneMonth
        ? aiFailurePerformanceRateInOneMonth.failureRate
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const aiFailurePerformanceRateInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return aiFailurePerformanceRateInOneDay
        ? aiFailurePerformanceRateInOneDay.failureRate
        : 0;
    });
  }
};

// Strategy for escalation rate

export const escalationRateStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const escalationRateInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return escalationRateInOneMonth
        ? escalationRateInOneMonth.escalationRate
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const escalationRateInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return escalationRateInOneDay ? escalationRateInOneDay.escalationRate : 0;
    });
  }
};

export const averageBotResponseTimeStrategy = (data, isMonth) => {
  if (!isMonth) {
    return constants.MONTHS_ABBREV.map((month) => {
      const averageBotResponseTimeInOneMonth = data?.find(
        (el) => el?.month?.toLowerCase() === month.toLowerCase()
      );
      return averageBotResponseTimeInOneMonth
        ? averageBotResponseTimeInOneMonth.averageResponseTime
        : 0;
    });
  } else {
    return constants.DAYS.map((day) => {
      const averageBotResponseTimeInOneDay = data?.find(
        (el) => el.day.toString().toLowerCase() === day.toLowerCase()
      );
      return averageBotResponseTimeInOneDay
        ? averageBotResponseTimeInOneDay.averageResponseTime
        : 0;
    });
  }
};
